import React from 'react';

import { Anchor } from '@components/Anchor'
import { Content } from '@components/Content'
import { Seo } from '@components/Seo'

const AboutPage = () => {

  return (
    <>
      <Seo title="About" />
      <Content textblock={true}>
        <section className="item">
          <h1>About us</h1>
          <p>
            Going back as far as 2008, Nutriot started out as a simple music blog. A
            space where personal findings and favourites were shared with the world. It
            was the still the hey-day of the blog and during its most active time, an
            average of 500 readers frequented the site every day.
          </p>

          <p>
            After relocating from São Paulo to Vienna, the idea to release music was
            born. In 2012 the new label Nutriot Recordings was officially registered,
            but it took another three long years until the first record came out vinyl.
          </p>

          <p>
            Since then, we have released music from the far corners of the earth, from
            New Zealand to Brazil, the United Kingdom and our native Austria. We continue
            to seek out music that's challenging and obscure, as long as we enjoy
            listening it ourselves &mdash; no matter its genre or age.
          </p>
        </section>

        <section className="item">
          <h1>Get in touch</h1>
          <p>
            You can find our music on <Anchor href="https://nutriot.bandcamp.com">Bandcamp</Anchor> and most other digital
            stores and streaming services. Our releases are distributed by <Anchor href="https://kompakt.fm/distribution">Kompakt Distribution</Anchor>.
          </p>

          <p>
            If you wish to send us demos of your music, please get in touch on <Anchor href="https://soundcloud.com/nutriot">SoundCloud</Anchor>
          </p>

          <p>Members of the press can <Anchor href='/downloads/logo.zip'>download our logo</Anchor>.</p>
        </section>
      </Content>
    </>
  );
};

export default AboutPage
